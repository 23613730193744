// Import dependencies
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

// Import components
import EducationTimeline from "../../components/eduTimeline";

// Import data
import { education } from "../../data/data";

// Import styles
import "./index.scss";

const Education = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="educations flex" id="education">
      <div className="educations">
        <h3 data-aos="fade-up">Education</h3>
        <p className="educations__sub" data-aos="fade-up">
          Academic Adventures{" "}
        </p>
        <EducationTimeline data={education} />
      </div>
    </div>
  );
};

export default Education;
