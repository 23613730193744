// Description: Navbar component

// Import dependencies
import { Link } from "react-scroll";
import { FiX } from "react-icons/fi";
import { useState } from "react";

// Import images
import logo from "../../assets/images/Logo.svg";

// Import Styles
import "./index.scss";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const handleMenuToggle = () => {
    setOpen(!open);
  };

  const handleMenuItemClick = () => {
    setOpen(false);
  };
  return (
    <>
      <Link to="#">
        <img src={logo} alt="Degrande Logo" />
      </Link>
      <nav className={open ? "open" : "closed"}>
        <FiX className="close-icon" onClick={handleMenuToggle} />
        <svg
          className="menu-icon"
          onClick={handleMenuToggle}
          width="29"
          height="11"
          viewBox="0 0 29 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 1.5H27"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M13.9805 9H27.0189"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
        <ul className="flex">
          <li>
            <Link
              to="/"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={handleMenuItemClick}
              className="active"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={handleMenuItemClick}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="projects"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={handleMenuItemClick}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onClick={handleMenuItemClick}
            >
              Contact
            </Link>
          </li>{" "}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
