// Description: This is the home page of the application

// Import dependencies
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

// Import components

// Import styles
import "./index.scss";

// Import images
import lineart from "../../assets/images/lineart.png";

// Import data

// Home component
const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="home grid">
      <div className="home__left-col" data-aos="fade-right">
        <h1>Full-stack Software Development Services</h1>
        <p>
          Hi, I’m Yani Degrande, a student developer based in Flanders, Belgium,
          with a passion for exploring the latest trends and technologies in the
          world of computer programming and software development.
        </p>
        <div className="home__left-col__cta-btns flex">
          <Link
            to="projects"
            className="primary-cta"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            Browse my projects
          </Link>
          <Link
            to="contact"
            className="secondary-cta flex"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <span>Contact me</span>
            <svg width="19" height="9" viewBox="0 0 19 9" fill="none">
              <path
                d="M18.3536 4.85355C18.5488 4.65829 18.5488 4.34171 18.3536 4.14645L15.1716 0.964466C14.9763 0.769204 14.6597 0.769204 14.4645 0.964466C14.2692 1.15973 14.2692 1.47631 14.4645 1.67157L17.2929 4.5L14.4645 7.32843C14.2692 7.52369 14.2692 7.84027 14.4645 8.03553C14.6597 8.2308 14.9763 8.2308 15.1716 8.03553L18.3536 4.85355ZM0 5H18V4H0V5Z"
                fill="black"
              />
            </svg>
          </Link>
        </div>
      </div>
      <div className="home__right-col" data-aos="fade-left">
        <img src={lineart} alt="line art" />
      </div>
    </div>
  );
};

// Export Home component
export default Home;
