// Description: Contact page

// Import dependencies
import { memo, useEffect } from "react";
import { FiLinkedin, FiGithub, FiInstagram } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

// Import components

// Import styles
import "./index.scss";

// Footer component
const Footer = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const year = new Date().getFullYear();

  return (
    <div className="footer grid">
      <div className="container">
        <div class="footer__row grid">
          <div class="footer__row__section">
            <h6 className="footer__row__section__header" data-aos="fade-right">
              About
            </h6>
            <p class="footer__row__section__main" data-aos="fade-right">
              I am a student developer based in Flanders, Belgium. With a
              passion for exploring the latest trends and technologies in the
              world of computer programming and software development, I am
              committed to constantly growing and expanding my skills in the
              field.
            </p>
          </div>
          <div class="footer__row__section">
            <h6 className="footer__row__section__header" data-aos="fade-left">
              Quick Links
            </h6>
            <ul class="footer__row__section__main__links">
              <li data-aos="fade-left">
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  About
                </Link>
              </li>
              <li data-aos="fade-left">
                <Link
                  to="projects"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Projects
                </Link>
              </li>
              <li data-aos="fade-left">
                <Link
                  to="skills"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Skills
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="container">
          <div class="footer__row grid">
            <div class="footer__row__section__copyright">
              <p>
                Copyright &copy; {year} All Rights Reserved by{" "}
                <Link>Yani Degrande</Link>.
              </p>
            </div>

            <div class="footer__row__section">
              <ul class="footer__row__section__social-links">
                <li>
                  <Link2 to="https://github.com/Gametodie2" target="_blank">
                    <FiGithub />
                  </Link2>
                </li>
                <li>
                  <Link2
                    to="https://www.instagram.com/yani_degrande/"
                    target="_blank"
                  >
                    <FiInstagram />
                  </Link2>
                </li>
                <li>
                  <Link2 to="https://twitter.com/YaniDegrande" target="_blank">
                    <FaXTwitter />
                  </Link2>
                </li>
                <li>
                  <Link2
                    to="https://www.linkedin.com/in/yani-degrande-aa4416265/"
                    target="_blank"
                  >
                    <FiLinkedin />
                  </Link2>
                </li>
                <li>
                  <Link2
                    to="https://www.discordapp.com/users/459033794125627392"
                    target="_blank"
                  >
                    <FaDiscord />
                  </Link2>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Export Footer component
export default memo(Footer);
