// Description: This is the main component of the application. It is the parent component of all other components.

import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/notFound";
import View from "./pages/view";
import TrainImages from "./pages/trains";

function App() {
  return (
    <Routes>
      <Route path="/" element={<View />} />
      <Route path="/comingsoon" element={<TrainImages />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
