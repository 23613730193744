import _1 from "../../assets/images/trains/_1.jpeg";
import _3 from "../../assets/images/trains/_3.jpeg";
import _4 from "../../assets/images/trains/_7.jpeg";
import _5 from "../../assets/images/trains/_8.jpeg";
import _6 from "../../assets/images/trains/hlr77_1.jpeg";
import _7 from "../../assets/images/trains/i11.jpeg";
import _8 from "../../assets/images/trains/m4.jpeg";
import _9 from "../../assets/images/trains/m6.jpeg";
import _10 from "../../assets/images/trains/ms08.jpeg";
import _11 from "../../assets/images/trains/ms80.jpeg";
import _12 from "../../assets/images/trains/ms96.jpeg";

import "./index.scss";

const TrainImages = () => {
  const images = [_1, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12];
  return images.map((image, index) => (
    <div className="train-images" key={index}>
      <img src={image} alt="train" />
    </div>
  ));
};

export default TrainImages;
