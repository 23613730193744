// Description: Contact page

// Import dependencies
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FiAlertOctagon, FiUser, FiMail, FiPhone } from "react-icons/fi";
import { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import emailjs from "emailjs-com";

// Import components

// Import styles
import "./index.scss";

const validationRules = {
  name: {
    required: "Name is required",
    minLength: {
      value: 2,
      message: "Name must be at least 2 characters",
    },
  },
  email: {
    required: "Email is required",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address",
    },
  },
  message: {
    required: "Message is required",
    minLength: {
      value: 10,
      message: "Message must be at least 10 characters long",
    },
  },
  phone: {
    pattern: {
      value: /^[0-9]{10}$/,
      message: "Invalid phone number",
    },
  },
};

function LabelInput({ name, label, icon, isInvalid, optional, id, ...rest }) {
  const { register, isSubmitting } = useFormContext();

  return (
    <div className="form__group flex" data-aos="fade-right">
      <div className="label-group flex">
        <label
          htmlFor={name}
          className={`form__label ${isInvalid ? "invalid " : " "}`}
        >
          {label}
        </label>
        {optional && <p>(Optional)</p>}
      </div>
      <div className={`form-input ${isInvalid ? "invalid " : " "}flex`}>
        <div className="form-input__icon">{icon}</div>
        <div className="form-input__input">
          <input
            {...register(name, validationRules[name])}
            type="text"
            name={name}
            id={name}
            className="form__control"
            autoComplete="off"
            disabled={isSubmitting}
            {...rest}
          />
        </div>
        {isInvalid && (
          <div className="form-input__error">
            <FiAlertOctagon />
          </div>
        )}
      </div>
    </div>
  );
}

function MessageInput({ name, label, isInvalid, id, ...rest }) {
  const { register, isSubmitting } = useFormContext();

  return (
    <div className="form__group message flex" data-aos="fade-left">
      <label
        htmlFor={name}
        className={`form__label ${isInvalid ? "invalid " : " "}`}
      >
        {label}
      </label>
      <div className={`form-input ${isInvalid ? "invalid " : " "}flex`}>
        <div className="form-input__input">
          <textarea
            {...register(name, validationRules[name])}
            name={name}
            id={name}
            className="form__control"
            autoComplete="off"
            disabled={isSubmitting}
            {...rest}
          />
        </div>
        {isInvalid && (
          <div className="form-input__error">
            <FiAlertOctagon />
          </div>
        )}
      </div>
    </div>
  );
}
// Contact page
const Contact = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);

  const sendEmail = async (data) => {
    console.log(data);
    console.log(process.env.REACT_APP_SERVICE_ID);
    console.log(process.env.REACT_APP_TEMPLATE_ID);

    await emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      {
        from_name: "yanidegrande.com",
        name: data.name,
        email: data.email,
        phone: `${data.phone ? data.phone : "Not provided"}`,
        message: data.message,
      },
      process.env.REACT_APP_USER_ID
    );

    console.log("Email sent successfully");
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await sendEmail(data);
      setErrorMessage("Message sent successfully.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } catch (error) {
      console.log(error);
      setErrorMessage("Something went wrong. Please try again later.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } finally {
      reset();
    }
  };

  const isObjectEmpty = (obj) => {
    return Object.values(obj).some((value) => !!value);
  };

  const watchAllFields = watch();

  useEffect(() => {
    setIsEmpty(isObjectEmpty(watchAllFields));
  }, [watch, isEmpty, watchAllFields]);

  return (
    <div className="contact" id="contact">
      <h3 data-aos="fade-right">Contact</h3>
      <p data-aos="fade-right">
        Love to hear from you,
        <br />
        Get in touch 👋
      </p>
      <div className="form__container">
        <FormProvider {...{ register, handleSubmit, errors }}>
          <form
            className="contact__form grid"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="contact__form__left-col">
              <LabelInput
                name="name"
                label={errors.name ? errors["name"].message : "Name"}
                icon={<FiUser />}
                placeholder="John Doe"
                isInvalid={errors.name}
              />
              <LabelInput
                name="email"
                icon={<FiMail />}
                placeholder="John.Doe@gmail.com"
                label={errors.email ? errors["email"].message : "Email"}
                isInvalid={errors.email}
              />
              <LabelInput
                name="phone"
                icon={<FiPhone />}
                placeholder="1234567890"
                label={errors.phone ? errors["phone"].message : "Phone"}
                isInvalid={errors.phone}
                optional={true}
              />
            </div>
            <div className="contact__form__right-col">
              <MessageInput
                name="message"
                placeholder="Your message..."
                label={errors.message ? errors["message"].message : "Message"}
                isInvalid={errors.message}
              />
            </div>

            <div className="form__submit">
              <button
                className="form__submit__button"
                type="submit"
                disabled={isSubmitting}
                data-aos="fade-right"
              >
                <span>Send message</span>
              </button>
              {isEmpty ? (
                <button
                  className="form__reset__button"
                  disabled={isSubmitting}
                  data-aos="fade-right"
                  type="reset"
                  onClick={() => reset()}
                >
                  <span>Cancel</span>
                </button>
              ) : null}
            </div>
          </form>
        </FormProvider>
        {errorMessage && <div className="form__error">{errorMessage}</div>}
      </div>
    </div>
  );
};

export default Contact;
