// Description: This component renders the projects page

// Import dependencies
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { FiGithub, FiLink } from "react-icons/fi";

// Import components
import ProjectItem from "../../components/projectItem";
import Tag from "../../components/tags";

// Import data
import { projects } from "../../data/data";

// Import styles
import "./index.scss";

function ProjectSlider({ projects }) {
  const [selectedProject, setSelectedProject] = useState(projects[0]);

  const handleItemClick = (project) => {
    setSelectedProject(project);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    console.log("selectedProject: ", selectedProject.title);
  }, [selectedProject]);

  return (
    <div className="project-slider flex">
      <div className="project-details grid">
        <div className="project-image" data-aos="fade-right">
          <img src={selectedProject.image} alt={selectedProject.title} />
        </div>
        <div className="project-info flex">
          <h3 data-aos="fade-left">{selectedProject.title}</h3>
          <p className="project-desc" data-aos="fade-left">
            {selectedProject.description.split("\n").map((item, i) => {
              return (
                <span key={i}>
                  {item}
                  <br />
                </span>
              );
            })}
          </p>
          <div className="project-buttons flex" data-aos="fade-left">
            <div className="project-tags flex">
              {selectedProject.tags.map((tag) => (
                <Tag key={tag} tag={tag} />
              ))}
            </div>
            <div className="project-links flex">
              {selectedProject.github && (
                <a
                  href={selectedProject.github}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FiGithub />
                </a>
              )}
              {selectedProject.link && (
                <a href={selectedProject.link} target="_blank" rel="noreferrer">
                  <FiLink />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="project-slider__slider flex" data-aos="fade-up">
        {projects.map((project) => {
          return (
            <ProjectItem
              key={project.id}
              project={project}
              onClick={handleItemClick}
              active={selectedProject.id === project.id}
            />
          );
        })}
      </div>
    </div>
  );
}

// Projects page
const Projects = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="projects flex" id="projects">
      <h3 data-aos="fade-up">Projects</h3>
      <ProjectSlider projects={projects} />
    </div>
  );
};

// Export projects page
export default Projects;
