// Description: Project item component

// Import dependencies
import { Link } from "react-scroll";

// Import styles
import "./index.scss";

// Project item component
const ProjectItem = ({ project, onClick, active }) => {
  const handleClick = () => {
    // Handle click event to display project details
    onClick(project);
  };

  return (
    <Link to="projects" spy={true} smooth={true} offset={0} duration={500} className={`project-item ${active ? "clicked" : ""}`} onClick={handleClick}>
      <img src={project.image} alt={project.title} />
    </Link>
  );
};

export default ProjectItem;
