// Description: Skills page

// Import dependencies
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

// Import components
import Tag from "../../components/tags";

// Import data
import { TechnicalTags, SoftTags } from "../../data/data";

// Import styles
import "./index.scss";

// Skills page
const Skills = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="skills flex" id="skills">
      <div className="skills">
        <h3 data-aos="fade-up">Skills</h3>
        <p className="skills__sub" data-aos="fade-up">
          Technical skills{" "}
        </p>
        <div className="skills__tags flex">
          {TechnicalTags.map((tag) => {
            return <Tag tag={tag} key={tag} />;
          })}
        </div>
        <p className="skills__sub" data-aos="fade-up">
          Soft skills{" "}
        </p>
        <div className="skills__tags flex" data-aos="fade-up">
          {SoftTags.map((tag) => {
            return <Tag tag={tag} key={tag} />;
          })}
        </div>
      </div>
    </div>
  );
};

// Export skills page
export default Skills;
