// Description: About page

// Import dependencies
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FiDownload } from "react-icons/fi";

// Import Images
import aboutImage from "../../assets/images/yani.JPG";

import cv from "../../assets/documents/CV-Yani_Etienne_Hilda_Degrande.pdf";

// Import styles
import "./index.scss";

// -Import components
import Skills from "../skills";
import Education from "../education";
import Languages from "../languages";

// About page
const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <div className="about flex" id="about">
      <div className="about__section flex">
        <div className="about__left-col" data-aos="fade-right">
          <img
            className="about__left-col__image"
            src={aboutImage}
            alt="Yani Degrande"
          />
        </div>
        <div className="about__right-col" data-aos="fade-left">
          <h3>About Me</h3>
          <p>
            My name is Yani Degrande, and I am a {calculateAge("2002-12-12")}
            -year-old computer science student currently studying at HoGent in
            Belgium. My fascination with technology has driven me to pursue a
            degree in computer science, where I am learning a wide range of
            programming languages and software development tools. In my free
            time, I enjoy playing video games and keeping up with the latest
            trends in technology. I am excited about my future and eager to see
            where my studies and experiences will take me.
          </p>
          <Link to={cv} className="primary-cta flex" target="_blank" download>
            <span>Download my CV</span>
            <FiDownload />
          </Link>
        </div>
      </div>
      <div className="about__section flex">
        <Education />
        <Skills />
      </div>
      <div className="about__section flex">
        <Languages />
      </div>
    </div>
  );
};

// Export
export default About;
