// Import styles
import "./index.scss";

// Import dependencies
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

const EducationTimeline = ({ data }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="timeline flex">
      <div className="timeline-time flex">
        {data.map((item, index) => {
          const shouldDisplayStart =
            index === data.length - 1 || item.start !== data[index + 1].end;

          return (
            <div
              className="timeline-time__item flex"
              key={item.id}
              data-aos="fade-up"
            >
              <p>{item.end}</p>
              <div className="timeline-time__line"></div>
              {shouldDisplayStart && <p>{item.start}</p>}
            </div>
          );
        })}
      </div>
      <div className="timeline-data flex">
        {data.map((item) => {
          return (
            <div className="timeline__item" key={item.id} data-aos="fade-up">
              <div className="timeline__item__title">
                <div className="timeline__item__title__header flex">
                  <h4>{item.school}</h4>
                  <div className="timeline__item__title__header__line"></div>
                  <img src={item.logo} alt={item.school} title={item.school} />
                </div>
                <p>{item.title}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EducationTimeline;
