import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import '../styles/_animations.scss';

const AnimatedElement = ({ children, animationName, delay = 0, duration = 1 }) => {
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger animation when element is 50% in the viewport
    triggerOnce: true, // Only trigger animation once
  });

  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimated(true);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`animated ${animationName} ${animated ? 'animate__animated animate__fadeIn' : ''}`}
      style={{ animationDelay: `${delay}s`, animationDuration: `${duration}s` }}
    >
      {children}
    </div>
  );
};

export default AnimatedElement;

