import delaware from "../assets/images/delaware.png";
import todo from "../assets/images/todobot.gif";
import zatre from "../assets/images/Zatre.png";
import degrande from "../assets/images/DegrandeProject.png";
import bookstore from "../assets/images/BookStoreLogo.png";

import HoGentLogo from "../assets/images/HoGentLogo.png";
import UGentLogo from "../assets/images/UGentLogo.png";
import HeiligeFamilieLogo from "../assets/images/HeiligeFamilieLogo.png";

export const TechnicalTags = [
  "HTML",
  "CSS",
  "JavaScript",
  "React.js",
  "Tailwind",
  "Java",
  "Python",
  "SQL",
  "Sass",
  "MySQL",
  "Node.js",
  "Git",
  "REST API",
  "Bootstrap",
  "JSON",
  "Prisma",
  "JPA",
  "Spring Boot",
  "Discord.js",
  "Kotlin",
  "JavaFX",
  "C#",
  ".NET",
  "Blazor",
];

export const SoftTags = ["Helpful", "Friendly", "Organized", "Punctual"];

export const projects = [
  {
    id: 1,
    title: "Zatre",
    image: zatre,
    description:
      "As a group of five students we build an existing game called Zatre as a school project. \nThroughout the development process, we worked collaboratively to design and implement various features of the game, including the user interface, game mechanics, and scoring system. Each member of the team brought unique skills and ideas to the project, which helped us to overcome challenges and achieve our goals.\nOur team was made up of Casper De Bock, Thomas Dewilde, Victor Gevaert, Warre Vandenhoucke, and myself, and we all contributed to the success of the project in our own way. It was a great experience to work together and see our vision come to life, and we hope that our game will be enjoyed by many players in the future.",
    tags: ["Java", "css", "JavaFX", "MySQL"],
    link: "",
    github: "https://github.com/Gametodie2/Zatre",
  },
  {
    id: 2,
    title: "Delaware B2B Portal - Web Application",
    image: delaware,
    description:
      "Our project involved developing a comprehensive solution for a school project that encompasses both web and desktop applications. We made sure to put in a great deal of effort into the development of this application to ensure that it functions smoothly and is easy to use.\n Our team is composed of five skilled members, each with a unique set of talents that we utilized to create this project. We are extremely proud of the outcome of our hard work and dedication, and we can't wait to showcase it for our school project.\nOverall, this project was a great learning experience for us, allowing us to gain valuable insights into software development, project management, and teamwork.\nTeammembers: Sebastiaan Delodder, Thomas Odvart, Warre Vandenhoucke, Jente Coppejans, and myself",
    tags: ["React", "scss", "Node", "MySQL", "Prisma"],
    link: "https://sdp2-web-frontend.onrender.com/",
    github: "https://github.com/Gametodie2/Delaware_B2B_Web",
  },
  {
    id: 3,
    title: "Todo Bot",
    image: todo,
    description:
      "I created a Discord bot that provides a new level of convenience for users by allowing them to manage their to-do's within Discord. This bot is designed to make the process of task management much easier and more streamlined, and I am excited to share it with others.\nWith this bot, users can interact with it by sending commands or using the buttons to add, delete or edit tasks. I spent a lot of time developing and testing this bot to ensure that it meets the needs of users, and I am proud of the result.\nCreating this bot required both technical expertise and creativity. I worked hard to create an interface that was intuitive and easy to use, while also building a solid foundation to ensure that the bot is stable and reliable. I believe that this bot will be a valuable tool for many Discord users, and I look forward to seeing how it is received.",
    tags: ["Node", "Discord.js"],
    link: "https://discord.gg/SyE4sw5kM4",
    github: "",
  },
  {
    id: 4,
    title: "Degrande",
    image: degrande,
    description:
      "As part of a school project, I designed and developed a website for a fictional company that offers reservations for a petting zoo. The website features an intuitive and user-friendly interface that allows users to browse available options and make reservations. Additionally, the website also includes an admin panel to manage the website and reservations.\nThe admin panel was a particularly challenging aspect of this project, as it required a robust backend system that could handle large volumes of data and user requests. With Node.js, I was able to create a secure and reliable system that allows authorized users to manage the website and reservations with ease.\nIt was a challenging but rewarding experience, and I believe that it demonstrates my ability to create high-quality web applications using modern development tools and techniques. I hope that this website will serve as a valuable resource for petting zoo enthusiasts and inspire others to explore the exciting world of web development.",
    tags: ["React", "scss", "Node", "MySQL"],
    link: "",
    github: "https://github.com/Gametodie2/DegrandeProject",
  },
  {
    id: 5,
    title: "Yani's Library",
    image: bookstore,
    description:
      "Yani's Library is a website I developed for a school project. The goal of the project was to create a platform where users can browse and like books, while administrators have the ability to add new books to the library. The website was built using Java, JPA, Spring Boot, MySQL, and CSS. I designed an intuitive and user-friendly interface, ensuring a seamless browsing experience for book enthusiasts.\nOne of the notable features of Yani's Library is the implementation of a RESTful API for handling book data. I developed a robust backend system using Java and Spring Boot, allowing users to interact with the application's data through well-defined endpoints. This REST functionality enables efficient communication between the frontend and backend components of the website.",
    tags: ["Java", "JPA", "Spring Boot", "MySQL", "CSS"],
    link: "",
    github: "https://github.com/Gametodie2/Yani_Library",
  },
  {
    id: 6,
    title: "Delaware B2B Portal - Desktop Application",
    image: delaware,
    description:
      "The Delaware B2B Portal is a comprehensive software solution developed by a team of five skilled members as part of a school project. This project involved the development of both web and desktop applications. The team, consisting of Sebastiaan Delodder, Thomas Odvart, Warre Vandenhoucke, Jente Coppejans, and the project requester, created a feature-rich application that offers a user-friendly interface and seamless functionality.\nThe desktop application aims to streamline business-to-business (B2B) operations, providing efficient management tools. The technologies used for this project include Java, JPA, MySQL, and CSS. The team dedicated significant effort to ensure a smooth user experience and deliver a high-quality final product. Through this project, the team gained valuable insights into software development, project management, and effective collaboration.",
    tags: ["Java", "JPA", "MySQL", "CSS"],
    link: "",
    github: "https://github.com/Gametodie2/Delaware_B2B_Desktop",
  },
];

export const education = [
  {
    id: 1,
    title: "Bachelor’s degree, Computer Science ",
    school: "HoGent",
    logo: HoGentLogo,
    start: "2021",
    end: "2024",
  },
  {
    id: 2,
    title: "Bachelor of Science - BS, Industrial Engineering (Quit)",
    school: "UGent",
    logo: UGentLogo,
    start: "2020",
    end: "2021",
  },
  {
    id: 3,
    title: "High School Diploma, Science and Mathematics ",
    school: "Heilige Familie",
    logo: HeiligeFamilieLogo,
    start: "2014",
    end: "2020",
  },
];

export const languages = [
  {
    id: 1,
    title: "Dutch",
    level: "Native",
    percentage: "100",
  },
  {
    id: 2,
    title: "English",
    level: "Professional",
    percentage: "85",
  },
  {
    id: 3,
    title: "French",
    level: "Elementary User",
    percentage: "35",
  },
  {
    id: 3,
    title: "German",
    level: "Basic User",
    percentage: "15",
  },
];
