// - Import styles
import "./index.scss";

// - Import dependencies
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

const Language = ({ language }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="language flex">
      <div className="language__percentage flex" data-aos="fade-up">
        <p>{language.percentage}</p>
      </div>
      <div className="language__title" data-aos="fade-up">
        {language.title}
      </div>
      <div className="language__level" data-aos="fade-up">
        {language.level}
      </div>
    </div>
  );
};
export default Language;
