// Description: This file contains the tags component

// Import dependencies
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

// Import Styles
import "./index.scss";

// Tag component
const Tag = ({ tag }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="tag" data-aos="fade-up">
      <p>{tag}</p>
    </div>
  );
};

// Export component
export default Tag;
