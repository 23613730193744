// Description: This is the main component of the application. It is the parent component of all other components.

// Import Dependencies
import AnimatedElement from "../../features/AnimatedElement";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

// Import Components
import Navbar from "../../components/navbar";
import ScrollToTopButton from "../../components/scrollToTopButton";
import Footer from "../../components/footer";

// Import Pages
import Home from "../home";
import About from "../about";
import Projects from "../projects";
import Contact from "../contact";

const View = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="wrapper" data-aos="fade-down">
        <header className="flex">
          <Navbar />
        </header>
        <main>
          <AnimatedElement>
            <Home />
          </AnimatedElement>
          <About />
          <Projects />
          <Contact />
          <Footer />
        </main>
        <footer></footer>
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default View;
