import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import "./index.scss";

const NotFound = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="not-found">
      <div className="not-found__content">
        <h1 className="not-found__title" data-aos="fade-up">
          404
        </h1>
        <p className="not-found__description" data-aos="fade-up">
          Oops! Looks like you're lost.
        </p>
        <p className="not-found__description" data-aos="fade-up">
          The page you are looking for does not exist.
        </p>
        <Link to="/" className="not-found__link" data-aos="fade-up">
          Go back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
