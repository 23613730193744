// - Import styles
import "./index.scss";

// - Import dependencies
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

// - Import components
import Language from "../../components/language";

// - Import data
import { languages } from "../../data/data";

const Languages = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="languages">
      <div className="languages">
        <h3 data-aos="fade-up">Languages</h3>
        <p className="languages__sub" data-aos="fade-up">
          Communication Abilities{" "}
        </p>
        <div className="languages-group flex">
          {languages.map((language) => (
            <Language key={language.id} language={language} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Languages;
